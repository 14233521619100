export const strings = {
  en: {
    beamAttribution: () => `Powered by Beam`,
    ctaTitle: () => `Choose your impact`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Select a nonprofit and ${donationPercentage}% of your purchase will be donated, at no extra cost.`,
    inlineSeparator: () => `: `,
  },
  fr: {
    beamAttribution: () => `Optimisé par Beam`,
    ctaTitle: () => `Choisissez votre cause`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Choisissez un organisme à but non lucratif et ${donationPercentage} % lui sera versé en votre nom, sans frais supplémentaires.`,
    inlineSeparator: () => ` : `,
  },
  de: {
    beamAttribution: () => `Unterstützt von Beam`,
    ctaTitle: () => `Wähle deinen Impact`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Wähle eine der gemeinnützigen Organisationen und spende ${donationPercentage}% deines Einkaufs ohne zusätzliche Kosten`,
    inlineSeparator: () => `: `,
  },
  es: {
    beamAttribution: () => "Ofrecido por Beam",
    ctaTitle: () => "Elige tu contribución",
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Elige una organización sin fines de lucro y donaremos ${donationPercentage}% de tu compra sin coste adicional.`,
    inlineSeparator: () => ": ",
  },
  it: {
    beamAttribution: () => "Gestito da Beam",
    ctaTitle: () => "Scegli dove fare la differenza",
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Seleziona un'organizzazione no-profit a cui devolvere l’${donationPercentage}% del tuo acquisto, senza costi aggiuntivi`,
    inlineSeparator: () => ": ",
  },
  pl: {
    beamAttribution: () => `Wspierany przez Beam`,
    ctaTitle: () => `Wybierz inicjatywę, którą chcesz wesprzeć`,
    ctaMessage: ({ donationPercentage = "1" } = {}) =>
      `Wybierz organizację, której przekażesz ${donationPercentage}% wartości Twoich zakupów – bez żadnych dodatkowych kosztów!`,
    inlineSeparator: () => `: `,
  },
};
